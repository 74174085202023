/*:root {
  --secondary: #00C134;
  --primarycolor : #870000;
  --primarysecondcolor : #121826;
  --mainfontcolor : #00008B;
  --bgcolor : #E4E4E4;
}
*/


@font-face {
    font-family:'Zawgyi-One';
    src: url('/font/zawgyi.woff') format('woff'), url('/font/zawgyi.ttf') format('ttf');
}

@font-face {
    font-family:'Myanmar3';
    /* src:local('Myanmar3'), url('https://mmwebfonts.comquas.com/fonts/myanmar3.woff') format('woff'), url('https://mmwebfonts.comquas.com/fonts/myanmar3.ttf') format('ttf'); */
    src: url('/font/myanmar3.woff') format('woff'), url('/font/myanmar3.ttf') format('ttf');
}

select {
  padding: 10px !important;
}
.wrapper__1{
    /* border: 1px solid ; */
    border-radius: 20px;
    padding:20px;
    margin: 10px auto;
    /* background-color:; */
    text-align: center;
    background-color: burlywood;
    width:50%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    
}
.wrapper__2{
    border: 1px solid ;
    border-radius: 20px;
    padding:20px;
    margin: 10px auto;
    background-color:#FF3B93;
    width:50%;
    text-align: center;

    
}
.wrapper__3{
    border: 1px solid ;
    border-radius: 20px;
    padding:20px;
    margin: 10px auto;
    background-color:#FAA314;
    width:50%;
    text-align: center;
}

/* @font-face {
    font-family:'Pyidaungsu';
    src:local('Pyidaungsu'), url('/fonts/Pyidaungsu-2.1_Regular.woff') format('woff'), url('/fonts/Pyidaungsu-2.1_Regular.ttf') format('ttf');
}

@font-face {
    font-family:'Pyidaungsu';
    src:local('Pyidaungsu'), url('/fonts/Pyidaungsu-2.1_Bold.woff') format('woff'), url('/fonts/Pyidaungsu-2.1_Bold.ttf') format('ttf');
    font-weight:bold;
} */



*{
    font-family: "Lato","Myanmar3";
    font-size: medium;
    margin: 0;
    padding: 0;
}

a {
    color: #fff;
}

.row {
    clear: both;
    width: 100%;
}

.clear {
    clear: both;
}

.left {
    float: left;
}

.right {
    float: right;
}

.text-center {
    text-align : center;
}

.text-right {
    text-align : right;
}

.text-left {
    text-align : left;
}

.v-align {
    vertical-align:  middle;
}

.grid {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}

.grid-5 {
    width: 4.99%;
}

.grid-10 {
    width: 9.99%;
}

.grid-15 {
    width: 14.99%;
}

.grid-20 {
    width: 19.99%;
}

.grid-25 {
    width: 24.99%;
}

.grid-30 {
    width: 29.99%;
}

.grid-35 {
    width: 34.99%;
}

.grid-40 {
    width: 39.99%;
}

.grid-45 {
    width: 44.99%;
}
.grid-49 {
    width: 49.99%;
}

.grid-33 {
    width: 33.33%;
}

.grid-50 {
    width: 49.99%;
}

.grid-60 {
    width: 59.99%;
}

.grid-70 {
    width: 69.99%;
}

.grid-80 {
    width: 79.99%;
}

.grid-90 {
    width: 89.99%;
}

.grid-95 {
    width: 94.99%;
}

.grid-100 {
    width: 100%;
}

.h-80 {
    height: 79.99%;
}

.h-70 {
    height: 69.99%;
}

.h-60 {
    height: 59.99%;
}

.h-20 {
    height: 19.99%;
}

.h-30 {
    height: 29.99%;
}

.h-40 {
    height: 39.99%;
}

.flag-text {
    padding: 0px 10px;
}

.mh-40 {
    min-height:  39.99%;
}

.table {
    border-collapse: collapse;
    width: 100%;
    /*padding: 100px;*/
    border-spacing: 50px 0px 50px 0px;
    /*background: #fff;*/

}

.table td, .table tr {
    padding: 1px 10px;
}

.table th {
    padding-top: 40px !important;
}

.table tr:last-child td {
    padding-bottom:  40px !important;
}

.table td, .table th {
    padding: .55rem;
}

.strip-table {
    cursor: pointer;
    border-collapse: collapse;
    width: 100%;
    border: 2px solid #e2e2e2;
}

.strip-table thead tr  {
    background: #e2e2e2;
    color : #149337;
}

.strip-table tbody tr  {
    border-bottom: 1px solid #e2e2e2;
}

.strip-table tbody tr  td tr:last-child {
    border-bottom: 0px;
}

.strip-table tbody tr:hover  {
    color: #fff;
    background: #149337;
}

.strip-table tbody tr:hover td  {
    color: #fff;
}

.p-4{
    padding: 4px;
}

.pt-4{
    padding-top: 4px;
}

.p-10 {
    padding:  10px;
}

.pt-10{
    padding-top: 10px;
}

.pt-20{
    padding-top: 20px;
}

.pt-30{
    padding-top: 30px;
}

.ptp-10{
    padding-top: 10%;
}

.ptp-20{
    padding-top: 20%;
}

.ptp-30{
    padding-top: 30%;
}

.pbp-10{
    padding-bottom: 10%;
}

.pbp-20{
    padding-bottom: 20%;
}

.pbp-30{
    padding-bottom: 30%;
}

.pb-30{
    padding-bottom: 30px;
}


.pb-4{
    padding-bottom: 4px;
}

.pb-10{
    padding-bottom: 10px;
}

.pr-4{
    padding-right: 4px;
}
.pr-5{
    padding-right: 5px;
}

.pr-10{
    padding-right: 10px;
}

.pl-5 {
    padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}


.pl-20 {
  padding-left: 20px;
}



.m-4 {
    margin: 4px;
}

.m-5 {
    margin: 5px;
}

.mt-4 {
    margin-top: 4px;
}

.mb-4 {
    margin-bottom: 4px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mb-0 {
    margin-bottom: 0px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-20 {
    margin-right: 20px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-20 {
    margin-left: 20px;
}

.bdr-right {
    border-right: 1px solid #e2e2e2;
}

.bdr-bottom {
    border-bottom: 1px solid #e2e2e2;
}

.bdr-top {
    border-top: 1px solid #e2e2e2;
}

.img-responsive {
    width: 100%;
    height: 100%;
}

.ml-20p {
    margin-left: 15%;
}

/* .page-content img {
    width: 100%;
} */

/* .pluginSkinLight > div {
    width: 100% !important;
} */

.no-bg {
    background: #fff;
}

div a.cont-wrap-link
{
    padding:0px;
    margin:0px;
    display:block;
    text-decoration:none;
    font-size: 14px;
}


div a.cont-wrap-link:active, div a.cont-wrap-link:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}

.tipo-box {
    text-align: center;
    background: blue;
    border: 1px solid #fff;
    display: inline-block;
    padding: 2px 6px;
    margin: 0px 7px;
}

.arrow-prev,.arrow-next {
    color : #00c134;
    font-weight: bold;
}

.selectDisable {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}



.btn {
    /*display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .375rem .75rem;
    font-size: 0.9rem;
    line-height: 1.5;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;*/

   /* width: 100%;*/
    display: inline-block;
    border: 1px solid var(--primarysecondcolor);
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: transparent;
    background-clip: padding-box;
    min-height: calc(1.5em + .75rem + 2px);
    text-align: center;
    cursor: pointer;
}

.btn-small {
    display: inline-block;
    font-weight: 300;
    color: #fff;
    text-align: left;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .305rem .50rem;
    /* font-size: 1rem; */
    font-size: 0.9rem;
    line-height: 1.5;
    border-radius: 5px;
}

.btn:active {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.btn-success {
    color: #fff;
    background-color: var(--primarysecondcolor);
    border-color: var(--primarysecondcolor);
}
.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;

}
.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-yellow {
   /* color: #fff;
    background-color: rgb(199, 154, 38) ;
    border-color: rgb(199, 154, 38) ;*/

    /* background: linear-gradient(90deg, #F9A000 0%, #D8BF00 100%); */
    background: linear-gradient(90deg, #0bccff 0%, #0bccff 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
   /* padding-top: 6px;*/
}

.btn-yellow_guide {
    background: linear-gradient(90deg, #F9A000 0%, #D8BF00 100%);
    border-color: var(--primarysecondcolor);
}

.btn-next{
  background: linear-gradient(90deg, #F9A000 0%, #D8BF00 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  float: center;
}

.btn-danger {
    color: #fff;
    background-color: #FF0000 ;
    border-color: #FF0000 ;
}

.btn:hover {
    opacity: 0.9;
    filter: alpha(opacity=90); /* For IE8 and earlier */
 }

button, select {
    text-transform: none;
}

select option {
    color : #000;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
    /*line-height: calc(1.5em + .75rem + 2px);*/
    line-height: 55px;
}

.form-control {
    width: 100%;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid var(--primarysecondcolor);
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: transparent;
    background-clip: padding-box;
    min-height: calc(1.5em + .75rem + 2px);

   /* display: block;
    width: 100%;
    min-height: calc(1.5em + .75rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fbfbfb;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-bottom: .7rem;
    padding: 5px 0px;*/
}

/*.form-control option {
    padding: 0px 5px;
}*/

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.round-img {
    border-radius: 50%;
}



.table-border {
    border: 1px solid #e2e2e2;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.table-border tr {
    padding-top: 20px;
    padding-bottom: 20px;
    /* background: #e2e2e2; */
    border-bottom: 1px solid #fff;
}

.responsive-table td, .responsive-table th {
    padding: .75rem;
}

.Spin {
    animation: App-logo-spin infinite 2s linear;
}

.comment {
    display: block;
    width: 90%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;

}



.pointer {cursor: pointer;}

.align-middle {
    vertical-align: middle !important;
}

.no-bd {
    border: 0px !important;
}

.no-padding {
    width: 50px;
    padding: 0px !important;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
        border-top-color: currentcolor;
        border-top-style: none;
        border-top-width: 0px;
    border-top: 1px solid
    rgba(0,0,0,.1);
}

/* .box-shadow {
    -webkit-box-shadow: 5px 5px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 5px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 5px 5px 2px 0px rgba(0,0,0,0.75);
} */
.list-box-shadow {
   /* box-shadow: 2px 0px 5px 2px rgba(147, 138, 138, 0.25);
    -webkit-box-shadow: 2px 0px 5px 2px rgba(147, 138, 138, 0.25);*/
    border: 2px dotted blueviolet;
    margin-bottom: 10px;
}

.box-shadow {
   /* -webkit-box-shadow: 5px 0px 10px 2px rgba(0,0,0,0.25);
     box-shadow: 5px 1px 2px 0px rgba(0,0,0,0.25);
    box-shadow: 5px 0px 10px 2px rgba(0,0,0,0.25);
    border-radius: 20px;*/
    border-radius: 20px;
    border: 1px solid #e2e2e2;
}

.ui-modal-overlay {
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1000;
    padding: 1.2rem;
    color : #000;
}

.ui-modal {
     max-width: 800px;
    position: relative;
    padding: 1.2rem;
    background: #ffffff;
        background-clip: border-box;
    background-clip: padding-box;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    margin : 0 auto;
}

.ui-modal-content {
  background-color: white;
}

.disabled {
    cursor: not-allowed !important;
}


.popover__title {

/*  line-height: 36px;*/
  text-decoration: none;
  color: white;
  text-align: center;
 /* padding: 15px 0;*/
}

.popover__wrapper {
  position: relative;
  margin-top: 1.5rem;
  display: inline-block;
}
.popover__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0px;
    transform: translate(0, 10px);
    background-color: var(--primarycolor);
    padding: 1.5rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: auto;
    right: -100px;
    color: #fff;
}
.popover__content:before {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0px;
    transform: translate(0, 10px);
    background-color: var(--primarycolor);
    padding: 1.5rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: auto;
    right: -100px;
    color: #fff;
}
.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.popover__message {
  text-align: left;
}

.tab-holder {
    /*background: #fff;*/
    color : #fff;
    margin-top : 10px;
    margin-bottom : 10px;
    padding: 10px 0px;
}

.tab-holder:hover, .tab-holder.active {
    color : #FFE200;
    /*background: var(--primarycolor);*/
    border-bottom: 1px solid #FFE200;
/*    padding: 10px 0px;*/
}

.scrollup {
  background-color: #171717;
  bottom: 100px;
  color: #ffffff;
 /* font-size: 24px;*/
/*  height: 40px;*/
 /* line-height: 40px;*/
  position: fixed;
  left: 47%;
  text-align: center;
  width: 100px;
  z-index: 99;
}

.avator-height {
    min-height: 70px;
}

.pointer-not-allowed {
  cursor: not-allowed;
}

.am-modal-alert-content {
  color : #000;
}

.middle-ellipse {
    position: absolute;
    width: 215px;
    height: 215px;
    left: 45%;
    top: 30%;

    background: #554A0B;
    filter: blur(100px);
    z-index: -2;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media (max-width:575px){
    .responsive-table td, .responsive-table th {
        padding: .75rem .2rem !important;
    }

    .ml-20p {
        margin-left: 7%;
    }

    .scrollup {
        left: 36%;
    }

/*    .current-number-block {
        background: none !important;
    }*/

    .current-number-block .blink_me {
        color : #FFE200;
    }

}

/* .league__table__design{
    background-color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
    color:#152e83;
} */

.flex-container {
    display: flex;
    flex-direction: row;
    font-size: 30px;
    text-align: center;
    border-radius: 20px;
    background-color: #e2ebff;
    margin-bottom: 10px;
    
  }
  .flex-container:nth-child( odd ) {
    display: flex;
    flex-direction: row;
    font-size: 30px;
    text-align: center;
    border-radius: 20px;
    background-color: #ffff;
    margin-bottom: 10px;
}
  .flex{
    display: flex;
    flex-direction: row;
    /* font-size: 30px; */
    /* text-align: center; */
    /* border-radius: 20px;
    background-color: #ffff;
    margin-bottom: 10px; */
  }
  .flex-item-left {
    /* background-color: #f1f1f1; */
    padding: 10px;
    color:#152e83;
    flex: 10%;
  }
  
  .flex-item-right {
    /* background-color: dodgerblue; */
    padding: 10px;
    flex: 50%;
    font-weight: 600;
    font-size: large;
    color: #152e83;
    font-family: Arial, Helvetica, sans-serif;
  }
  .flex-box{
    display: flex;
    height: 60px;
    justify-content: space-between;
  }
.flex-item-page{
    padding: 10px;
    flex: 50%;
    font-weight: 600;
    font-size: large;
    text-align:center;
    margin-top:10px;
    color: #152e83;
    font-family: Arial, Helvetica, sans-serif;
}
.flex-item-img{
    padding: 10px;
    flex: 10%;
    font-weight: 600;
    font-size: large;
    color: #152e83;
    font-family: Arial, Helvetica, sans-serif;
}

  .my__page__tag{
    font-family: fangsong;
    font-size: large;
    font-weight: 600;
    color:#152e83;
  }


  .btn-version {
    display: inline-block;
    font-weight: 300;
    color: #fff;
    text-align: left;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .305rem .50rem;
    /* font-size: 1rem; */
    font-size: 0.9rem;
    line-height: 1.5;
    border-radius: 5px;
    background-color: #152e83;
}

.cardPrice {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
  }
  
  /* On mouse-over, add a deeper shadow */
  .cardPrice:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
.price{
    text-align: center;
    font-size: unset;
    font-weight: 600;
    font-family:Arial, Helvetica, sans-serif;
    text-align: center;
    padding: 20px;
}  
.buy{
    text-align: center;
    float: center;
    margin: 0px auto;
    padding: 10px;
    width: 100%;
    background-color: #152e83;
    color:white;
    font-weight: bold;
    /* cursor: pointer; */
}

.switchBtn {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switchBtn input { 
    opacity: 0;
    width: 0;
    height: 0;
  }

.ssslider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .imgTag{
    background-image: none;
  }
  .ssslider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .ssslider {
    background-color: #2196F3;
  }
  
  input:focus + .ssslider {
    box-shadow: 0 0 1px gray;
  }
  
  input:checked + .ssslider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sssliders */
  .ssslider.rounded {
    border-radius: 34px;
  }
  
  .ssslider.rounded:before {
    border-radius: 50%;
  }
  
@media (max-width: 800px) {
    
    .flex {
        flex-direction: column;
      }
  }

